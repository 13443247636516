import React from "react";
import { Link } from "react-router-dom";

const SubmitProject = () => {
  return (
    <section className="bg-white pb-12">
      <div className="container">
        <div
          className={`flex flex-wrap items-center justify-between rounded-lg border bg-[#0b8422de] py-8 px-6 xs:px-10 md:px-8 lg:px-10`}
        >
          <div className="w-full md:w-7/12 lg:w-2/3">
            <div className="mb-6 md:mb-0">
              <h4 className="mb-1 text-xl font-bold text-gray-100 xs:text-2xl md:text-xl lg:text-2xl">
                Submit Your Project
              </h4>
              <p className="text-base font-medium text-gray-100">
                Ensure you have the <strong>correct link</strong> to your
                project.
                <br /> You are required to submit the project with the{" "}
                <strong>correct details.</strong>
              </p>
            </div>
          </div>
          <div className="w-full md:w-5/12 lg:w-1/3">
            <div className="flex items-center space-x-3 md:justify-end">
              <Link
                to={"/submit"}
                className="inline-flex items-center justify-center rounded-md bg-primary py-[10px] px-8 text-center text-base font-semibold border border-white hover:bg-white hover:text-green-600 text-white hover:bg-opacity-90"
              >
                Submit
              </Link>
              {/* <button className="inline-flex items-center justify-center rounded-md bg-white py-[10px] px-8 text-center text-base font-semibold text-body-color shadow-card hover:bg-primary hover:text-white">
                Close
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubmitProject;
