import { Transition, Dialog, Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, Fragment, useState } from 'react';

import { Link } from 'react-router-dom';
import Register from '../Models/Register';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { courses } from './Courses';

const navigation = [
  { name: 'Home', href: '/' },
  // { name: "About", href: "/#about" },
  { name: 'Hackathon', href: '/hackathon' },
  {
    name: 'Courses',
    children: [
      { name: 'Scratch', href: '/course/2' },
      { name: 'Javascript', href: '/course/3' },
      { name: 'Web Development', href: '/course/4' },
      { name: 'Python', href: '/course/5' },
      { name: 'Robotics and IoT', href: '/course/6' },
      // { name: "IoT", href: "/course/6" },
      { name: 'Chess', href: '/course/7' },
    ],
  },
  {
    name: 'C3 Micro cloud',
    href: 'https://c3microcloud.com/c3-micro-cloud-funlings',
  },
  { name: 'Blog', href: '/#blog' },
  { name: 'Workshop', href: '/workshop' },
  { name: 'Magicbit', href: '/magicbit' },
];

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const openModal = () => {
  //   setShowModal(true);
  // };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // const handleDropdownClick = (index) => {
  //   setActiveDropdown(index === activeDropdown ? null : index);
  // };

  return (
    <header
      className={`absolute ${
        scrolled &&
        `sticky transition ease-in duration-200 transform bg-[#021029]`
      } inset-x-0 top-0 z-50`}
    >
      <nav
        className="flex items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Funlings</span>
            <img className="h-12 w-auto" src="/images/logo.png" alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 text-gray-200" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item, index) => (
            <div>
              {item.children ? (
                <Popover className="relative">
                  <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-200">
                    <span>{item.name}</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                      <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg">
                        <div className="p-4">
                          {courses.map((childItem) => (
                            <Link
                              key={childItem.name}
                              to={`/course/${childItem.id}`}
                              // spy={true}
                              // smooth={true}
                              // offset={-40}
                              // duration={900}
                              onClick={() => setMobileMenuOpen(false)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {childItem.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm font-semibold leading-6 text-gray-200"
                >
                  {item.name}
                </Link>
              )}
            </div>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 gap-4 lg:justify-end items-center">
          <Link
            to={'/signup'}
            className="text-sm border-2 p-2 bg-gray-200 hover:bg-blue-800 rounded-md font-semibold leading-6 text-blue-800 hover:text-gray-200"
          >
            Sign Up
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Funlings</span>
              <img className="h-8 w-auto" src="/images/logo.png" alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item, index) => (
                  <div>
                    {item.children ? (
                      <Popover className="relative">
                        <Popover.Button className="inline-flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900">
                          <span>{item.name}</span>
                          <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg">
                              <div className="p-4">
                                {courses.map((childItem) => (
                                  <Link
                                    key={childItem.name}
                                    to={`/course/${childItem.id}`}
                                    // spy={true}
                                    // smooth={true}
                                    // offset={-40}
                                    // duration={900}
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  >
                                    {childItem.name}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
                {/* {navigation.map((item) => (
                  
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))} */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {showModal && (
        <div>
          <Register onClose={closeModal} />
        </div>
      )}
    </header>
  );
};

export default Navbar;
