import React from "react";
import ColorTitles from "../utils/colorTitles";
// import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import HackathonFAQS from "../components/HackathonFAQS";
import SubmitProject from "../components/SubmitProject";

const Hackathon = () => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    Swal.fire({
      title: "Sorry!",
      text: "The Hackathon Has already started...",
      icon: "error",
      timer: 2000,
    });
    // emailjs
    //   .sendForm(
    //     "service_3wrzwm1",
    //     "template_hehmnsw",
    //     event.target,
    //     "GLR7jpB9N_qUxIula"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       Swal.fire({
    //         title: "Success!",
    //         text: "Your registration was successful!",
    //         icon: "success",
    //         timer: 2000,
    //       });
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );

    // Reset the form after submission if desired
    event.target.reset();
  };
  return (
    <div>
      <div className="bg-gray-700 h-20"></div>

      <div className="relative w-4/5 container mx-auto  bg-white py-16">
        <div className="container">
          <SubmitProject />
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <h1 className="mb-3 text-4xl font-bold leading-snug text-gray-700 sm:text-[42px] lg:text-[30px] ">
                  Funlings Virtual Kids Coding Hackathon:
                </h1>
                <h1 className="mb-3 text-4xl font-bold leading-snug text-gray-700 sm:text-[42px] lg:text-[20px] ">
                  Coding for a Brighter Future
                </h1>
                <p className="mb-8 max-w-[480px] text-base text-body-color">
                  Put your coding skills to the test by competing with other
                  students around Kenya for prizes & glory!
                </p>
                <div className=" md:pr-10 md:py-6">
                  <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                      </svg>
                    </div>
                    <div className="flex-grow pl-4">
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                        REGISTRATION
                      </h2>
                      <p className="leading-relaxed">
                        Student must register, between ages 7 to 14 years
                      </p>
                    </div>
                  </div>
                  <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                      </svg>
                    </div>
                    <div className="flex-grow pl-4">
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                        TEAM SIZE
                      </h2>
                      <p className="leading-relaxed">One contestant</p>
                    </div>
                  </div>
                  <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <circle cx="12" cy="5" r="3"></circle>
                        <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                      </svg>
                    </div>
                    <div className="flex-grow pl-4">
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                        CODING LANGUAGE
                      </h2>
                      <p className="leading-relaxed">
                        Scratch, Python, JavaScript, HTML and CSS, Thinkable,
                        Replit
                      </p>
                    </div>
                  </div>
                  <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </div>
                    <div className="flex-grow pl-4">
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                        NO. of Projects
                      </h2>
                      <p className="leading-relaxed">
                        1 PROJECT PER CONTESTANT Contestants cannot submit a
                        team project & a personal project.
                      </p>
                    </div>
                  </div>
                  <div className="flex relative">
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </div>
                    <div className="flex-grow pl-4">
                      <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                        SCORING
                      </h2>
                      <p className="leading-relaxed">
                        Projects will be scored across four criteria for a total
                        of 100 possible points.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative z-10 inline-block pt-11 lg:pt-0">
                  <img
                    src="/Robotics.jpg"
                    alt="hero"
                    className="max-w-full h-[500px] rounded-tl-[150px]  lg:ml-auto"
                  />
                  <span className="absolute -left-8 -bottom-8 z-[-1]">
                    <svg
                      width="93"
                      height="93"
                      viewBox="0 0 93 93"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* <circle cx="2.5" cy="2.5" r="2.5" fill="#3056D3" /> */}
                      <circle cx="2.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="2.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="24.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="46.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="68.5" cy="90.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="2.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="24.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="46.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="68.5" r="2.5" fill="#3056D3" />
                      <circle cx="90.5" cy="90.5" r="2.5" fill="#3056D3" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <section className=" py-8">
            <div className="container">
              <div
                className={`relative z-10 overflow-hidden rounded bg-slate-700 py-12 px-8 md:p-[70px]`}
              >
                <div className="flex flex-wrap items-center -mx-4">
                  <div className="w-full px-4 lg:w-1/2">
                    <span className="mb-2 text-base font-semibold text-white">
                      THEME
                    </span>
                    <h2 className="mb-6 text-xl w-[80%] font-bold leading-tight text-white sm:mb-8 sm:text-[38px] lg:mb-0">
                      Coding for a Brighter Future for Children
                    </h2>
                  </div>
                  <div className="w-full border-l-4 border-gray-400 px-4 lg:w-1/2">
                    <div className=" w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                      {/* <h2 className="text-sm title-font text-gray-200 tracking-widest">
                        CHALLENGE
                      </h2> */}
                      <h1 className="text-gray-100 text-3xl title-font font-medium mb-1">
                        CHALLENGE
                      </h1>

                      <p className="leading-relaxed text-gray-50 text-md">
                        Q: Create a captivating coding project using the
                        programming language of your choice. Your project should
                        merge elements of gaming and animation to unleash your
                        creativity, programming prowess, and imagination!
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="absolute top-0 left-0 z-[-1]">
                    <svg
                      width={189}
                      height={162}
                      viewBox="0 0 189 162"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx={16}
                        cy="-16.5"
                        rx={173}
                        ry="178.5"
                        transform="rotate(180 16 -16.5)"
                        fill="url(#paint0_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1={-157}
                          y1="-107.754"
                          x2="98.5011"
                          y2="-106.425"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" stopOpacity="0.07" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="absolute bottom-0 right-0 z-[-1]">
                    <svg
                      width={191}
                      height={208}
                      viewBox="0 0 191 208"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx={173}
                        cy="178.5"
                        rx={173}
                        ry="178.5"
                        fill="url(#paint0_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="-3.27832e-05"
                          y1="87.2457"
                          x2="255.501"
                          y2="88.5747"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" stopOpacity="0.07" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </section>

          <section className="text-gray-600 body-font">
            <div className="container px-5  mx-auto flex flex-wrap items-center">
              <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                <div className="text-gray-600 body-font">
                  <div className=" px-5 py-24 mx-2">
                    <div className="container mx-auto text-3xl font-bold w-full">
                      <ColorTitles text={" Timelines "} />
                    </div>
                    <div className="flex relative pt-10 pb-20 sm:items-center  mx-auto">
                      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                        1
                      </div>
                      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        {/* <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-12 h-12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                          </svg>
                        </div> */}
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                            Registrations Deadline
                          </h2>
                          <p className="leading-relaxed">20th September 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative pb-20 sm:items-center  mx-auto">
                      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                        2
                      </div>
                      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        {/* <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-12 h-12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                          </svg>
                        </div> */}
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                            Coding Period
                          </h2>
                          <p className="leading-relaxed">
                            Start Date: 20 September 2023
                          </p>
                          <p className="leading-relaxed">
                            End Date: 10 October 2023
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative pb-20 sm:items-center  mx-auto">
                      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                        3
                      </div>
                      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        {/* <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-12 h-12"
                            viewBox="0 0 24 24"
                          >
                            <circle cx="12" cy="5" r="3"></circle>
                            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                          </svg>
                        </div> */}
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                            Closing Submission
                          </h2>
                          <p className="leading-relaxed">10th October 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative pb-10 sm:items-center  mx-auto">
                      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                        4
                      </div>
                      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        {/* <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-12 h-12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </div> */}
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                            Top 10 Announcement
                          </h2>
                          <p className="leading-relaxed">15th October 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex relative pb-10 sm:items-center  mx-auto">
                      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                        5
                      </div>
                      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        {/* <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-12 h-12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </div> */}
                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">
                            Finals - Award ceremony
                          </h2>
                          <p className="leading-relaxed">26th October 2023</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-2/6 md:w-1/2 bg-gray-100 rounded-lg md:p-8 p-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                <form onSubmit={handleSubmit}>
                  <img
                    src="/images/logo.png"
                    className="mx-auto  h-20"
                    alt=""
                  />
                  <h2 className="text-center font-bold mb-4">
                    Kids Information
                  </h2>
                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="kids_first_name"
                        id="kids_first_name"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="kids_first_name"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        First name
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="kids_last_name"
                        id="kids_last_name"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="kids_last_name"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Last name
                      </label>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <label
                        htmlFor="grade"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Grade
                      </label>
                      <div className="">
                        <input
                          type="text"
                          name="grade"
                          id="grade"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />

                        {/* <select
                          id="age"
                          name="age"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>2 - 5 Years</option>
                          <option>6 - 10 Years</option>
                          <option>11 - 14 Years</option>
                          <option>15 - 17 Years</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <label
                        htmlFor="gender"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Gender
                      </label>
                      <div className="mt-2">
                        <select
                          id="gender"
                          name="gender"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    {/* <label
                      htmlFor="course"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Preffered Programming Language
                    </label> */}
                    <div className="mt-2">
                      <input
                        type="text"
                        name="course"
                        id="course"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="course"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Preffered Programming Language
                      </label>
                      {/* <select
                          id="course"
                          name="course"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>Chess</option>
                          <option>Robotics</option>
                          <option>IOT</option>
                          <option>Python</option>
                          <option>Scratch</option>
                          <option>Javascript</option>
                        </select> */}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <div className="mt-2">
                        <input
                          type="number"
                          name="age"
                          id="age"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                        <label
                          htmlFor="age"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          Age
                        </label>
                        {/* <select
                          id="age"
                          name="age"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>2 - 5 Years</option>
                          <option>6 - 10 Years</option>
                          <option>11 - 14 Years</option>
                          <option>15 - 17 Years</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      {/* <label
                        htmlFor="school"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        School
                      </label> */}
                      <div className="mt-2">
                        <input
                          type="text"
                          name="school"
                          id="school"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          required
                        />
                        <label
                          htmlFor="school"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                          School
                        </label>
                        {/* <select
                          id="course"
                          name="course"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          <option>Chess</option>
                          <option>Robotics</option>
                          <option>IOT</option>
                          <option>Python</option>
                          <option>Scratch</option>
                          <option>Javascript</option>
                        </select> */}
                      </div>
                    </div>
                  </div>

                  <h2 className="text-center font-bold my-4">
                    Parent/Guardian Information
                  </h2>
                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="parent_first_name"
                        id="parent_first_name"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="parent_first_name"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        First name
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="parent_last_name"
                        id="parent_last_name"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="parent_last_name"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Last name
                      </label>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="tel"
                        pattern="[0-9]{10}|\+[0-9]{12}"
                        name="parent_phone"
                        id="parent_phone"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=""
                        required
                      />
                      <label
                        htmlFor="parent_phone"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Phone number{" "}
                        <span className="hidden ">(073-456-7890)</span>
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="email"
                        name="parent_email"
                        id="parent_email"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="parent_email"
                        className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Email address
                      </label>
                    </div>
                    {/* <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="parent_relationship"
                        id="parent_relationship"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="parent_relationship"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Relationship{" "}
                        <span className="hidden">
                          (Ex. Parent/Sister/Brother)
                        </span>
                      </label>
                    </div> */}
                  </div>
                  <button
                    type="submit"
                    disabled
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Registrations Closed
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <HackathonFAQS />
    </div>
  );
};

export default Hackathon;
